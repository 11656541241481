import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";

import { parseRequest } from '../../tools/request';

import Button from 'react-bootstrap/Button';
// example: ZGVudGFscGx1c3wxfDE=

export default function MedicalForm(props) {
    //let { request } = useParams();
    const { request } = useParams();
    let navigate = useNavigate();

    const [pageError, setPageError] = useState(false);

    const [practice, setPractice] = useState({});
    const [formList, setFormList] = useState('');
    const [nextForm, setNextForm] = useState('medform');

    useEffect(() => {
        async function fetchData() {
            let sp = '';
    
            if (process.env.NODE_ENV === "development")
                sp = process.env.REACT_APP_SP;
            else
                sp = window.location.origin;
    
            let params = await parseRequest(request, sp);
    
            console.log(params);
    
            /*let params = {};
            params.cust_id = request_array[0];
            params.practice_id = request_array[1];
            params.patient_id = request_array[2];
            params.forms = request_array[3];
            params.mh_check = request_array[4];
            params.nhs = request_array[5];*/
    
            //console.log(request_array);
    
            if (params.forms === 'y' || params.forms === 'n') {
                params.mh_check = params.forms;
                params.forms = "all";
            } 
    
            //console.log(params);
                
            if (!params.cust_id || !params.practice_id || !params.patient_id) {
                //console.log('error');
                setPageError(true);
            }
        
            const requestOptions = {
                method: 'POST',
                mode: "cors",
                body: JSON.stringify({ action: 'get_practice', cust_id: params.cust_id, 
                    practice_id: params.practice_id, patient_id: params.patient_id, forms: params.forms, nhs: params.nhs })
            };
    
            fetch(sp+'/api/form_questions.php', requestOptions)
            .then(async response => {
    
                const data = await response.json();
    
                //console.log(data);
    
                // check for error response
                if (!response.ok) {
                    // get error message from body or default to response status
                    const error = (data && data.message) || response.status;
                    return Promise.reject(error);
                }
    
                //console.log(data);
    
                setPractice(data.practice);
                localStorage.setItem('practice', JSON.stringify(data.practice));
    
                if (params.forms === "all") {
                    setFormList(data.forms);
                    localStorage.setItem('forms', data.forms);
                } else {
                    setFormList(data.forms);
                    localStorage.setItem('forms', data.forms);
                }
                
                let formArr = localStorage.getItem('forms').split(",");
                setNextForm(formArr[0]);
    
                document.title = data.practice.practice_name;
            })
            .catch(error => {
                //setPageError(true);
                //console.error('There was an error!', error);
            });
        }
        
        fetchData();
    }, []);

    return (
    <>
        <div className="welcomePage" style={{paddingLeft: '6px', paddingRight: '6px'}}>
            <div className="formHeader"><h1>{practice.practice_name}</h1>                      
            {practice.practice_contact}</div>   
            <br/>
        { pageError ? 
        <> 
            <span>Either the request URL is wrong, or we can't connect to the server.<br/>Please try again shortly or contact the practice.</span>
        </>
        :
        <>
            <p>Welcome to our online patient forms system.</p>       
            <p>Before attending your next appointment, we would like you to complete the following...</p> 
            <div className="formList">
            { formList.split(",").indexOf('medform') >= 0 &&
                <><span>Your medical history</span><br/></>
            }
            { formList.split(",").indexOf('covid') >= 0 &&
                <><span>A COVID-19 screening questionnaire</span><br/></>
            }
            { formList.split(",").indexOf('consent') >= 0 &&
                <><span>Your consent for treatment</span><br/></>
            }
            { formList.split(",").indexOf('fp17pr') >= 0 &&
                <><span>Your NHS treatment declaration</span><br/></>
            }
            { formList.split(",").indexOf('payment') >= 0 &&
                <><br/><span>We require you to make a deposit payment</span></>
            }
            { formList.split(",").indexOf('contact') >= 0 &&
                <><br/><span>Let us know your contact details</span></>
            }
            </div> 
            <br/>       
            <Button style={{width:'100%', height: '3.5em'}} variant="outline-primary" onClick={() => navigate("/"+nextForm+"/"+request)}>Click/touch here to begin</Button> 
        </>
        }
        </div>
    </>
    );
}