import React, { useState, useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Grid from '@material-ui/core/Grid';
import SignaturePad from 'react-signature-pad-wrapper';
import { parseRequest } from '../../tools/request';

import Button from 'react-bootstrap/Button';

import ToggleButton from 'react-bootstrap/ToggleButton';
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';

import '../sigCanvas.css';
// example: ZGVudGFscGx1c3wxfDE=

export default function ConsentForm(props) {
    const sigCanvas = useRef({});
    const signatureEndRef = useRef({});
    const { request } = useParams();
    let navigate = useNavigate();

    const [formSaved, setFormSaved] = useState(false);
    const [formSaveError, setFormSaveError] = useState(false);

    const [serverPath, setServerPath] = useState('');
    const [pageError, setPageError] = useState(false);

    const [requestParams, setRequestParams] = useState({});
    const [questions, setQuestions] = useState([]);
    const [practice, setPractice] = useState({});

    const [nextForm, setNextForm] = useState('');

    useEffect(() => {
        fetchData();
    }, []);

    async function fetchData() {
        
        setPractice(JSON.parse(localStorage.getItem('practice')));

        let sp = '';

        if (process.env.NODE_ENV === "development")
            sp = process.env.REACT_APP_SP;
        else
            sp = window.location.origin;
        
        setServerPath(sp);

        let params = await parseRequest(request, sp);
    
        /*let request_parse = await parseRequest(request, sp);
        let request_array = request_parse.request_array; 

        let params = {};
        params.cust_id = request_array[0];
        params.practice_id = request_array[1];
        params.patient_id = request_array[2];*/

        setRequestParams(params);

        if (!params.cust_id || !params.practice_id || !params.patient_id) {
            setPageError(true);
        }
    
        const requestOptions = {
            method: 'POST',
            mode: "cors",
            body: JSON.stringify({ action: 'get_consentform', cust_id: params.cust_id, 
            practice_id: params.practice_id, patient_id: params.patient_id })
        };

        fetch(sp+'/api/form_questions.php', requestOptions)
        .then(async response => {

            const data = await response.json();

            //console.log(data);

            // check for error response
            if (!response.ok) {
                // get error message from body or default to response status
                const error = (data && data.message) || response.status;
                return Promise.reject(error);
            }

            setQuestions(data.consent);
        })
        .catch(error => {
            setPageError(true);
            //console.error('There was an error!', error);
        });

        let formArr = localStorage.getItem('forms').split(",");
        let formIndex = formArr.indexOf('consent');
        setNextForm(formArr[formIndex+1]);
    }

    const saveForm = () => {
        //console.log(questions);

        if (sigCanvas.current.isEmpty())
            return;

        let sigData = sigCanvas.current.toDataURL();

        const requestOptions = {
            method: 'POST',
            mode: "cors",
            body: JSON.stringify({ action: 'save_consent_form', cust_id: requestParams.cust_id, practice_id: requestParams.practice_id, 
                patient_id: requestParams.patient_id, answers: questions, signature: sigData })
        };

        //console.log(requestOptions);

        fetch(serverPath+'/api/form_answers.php', requestOptions)
        .then(async response => {

            const data = await response.json();

            // check for error response
            if (response.ok === false) {
                // get error message from body or default to response status
                const error = (data && data.message) || response.status;
                return Promise.reject(error);
            }
            else {
                //setFormSaved(true);
                //console.log('form saved');
                navigate("/"+nextForm+"/"+request);
            }
        })
        .catch(error => {
            setFormSaveError(true);
            //console.error('There was an error!', error);
        });
    }

    const handleSwitchChange = (val, id) => {
        let _questions = Array.from(questions);

        for (let i=0; i<_questions.length; i++) {
            if (_questions[i].item_key === id) {
                _questions[i].answer = val;
                break;
            }
        }

        setQuestions(_questions);
    };

    const clearSig = () => {
        sigCanvas.current.clear();
    }

    return (
    <div style={{paddingLeft: '6px', paddingRight: '6px'}}>
        <h1 className="formHeader">{practice.practice_name}</h1>
        { pageError ? 
        <> 
            <span>Either the request URL is wrong, or we can't connect to the server.<br/>Please try again shortly or contact the practice.</span>
        </>
        :
        <>
            { !formSaved  &&
            <>
                <div style={{paddingBottom: '6px', fontWeight: 'bold'}}>Please read the following consent form 
                and sign in the box at the bottom.</div>
                <div className="consentText" dangerouslySetInnerHTML={{__html: questions}}/>
                { formSaveError &&
                    <div className="errorText">There was a problem saving the form, please try again shortly.</div>
                } 
                <div className="signBox">
                    <span><b>Please sign in the box below.</b> Rotate your device if you need to make the box bigger.</span>
                    <SignaturePad ref={sigCanvas} canvasProps={{className: "signatureCanvas"}}/>
                    <Grid container spacing={1} ref={signatureEndRef} >
                        <Grid item xs zeroMinWidth>
                            <Button style={{width:'100%', marginRight: '8px', height: '3.5em'}} variant="success" onClick={saveForm}>Save Form</Button>
                        </Grid>
                        <Grid item xs={4} md={2}> 
                            <Button style={{width:'100%', height: '3.5em'}} variant="outline-secondary" onClick={clearSig}>Re-sign</Button>
                        </Grid>
                    </Grid>
                </div>
            </>
            }            
        </>
        }
    </div>
    );
}