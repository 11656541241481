import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';

import Button from 'react-bootstrap/Button';
// example: ZGVudGFscGx1c3wxfDE=

export default function MedicalForm(props) {
    const [practiceEmail, setPracticeEmail] = useState('');
    const [requestOK, setRequestOK] = useState(false);

    const setValue = (e) => {
        setPracticeEmail(e.currentTarget.value);
    }

    const saveForm = () => {
        const requestOptions = {
            method: 'POST',
            mode: "cors",
            body: JSON.stringify({ action: 'request_login', practice_email: practiceEmail })
        };

        //console.log(requestOptions);

        let sp = '';
    
        if (process.env.NODE_ENV === "development")
            sp = process.env.REACT_APP_SP;
        else
            sp = window.location.origin;

        fetch(sp+'/api/admin.php', requestOptions)
        .then(async response => {

            const data = await response.json();

            // check for error response
            if (response.ok === false) {
                // get error message from body or default to response status
                const error = (data && data.message) || response.status;
                return Promise.reject(error);
            }
            else {
                setRequestOK(true);
                //navigate("/covid/"+request);
            }
        })
        .catch(error => {
            console.error('There was an error!', error);
        });
    }

    return (
    <>
        { requestOK === false ?
        <div className="welcomePage" style={{paddingLeft: '6px', paddingRight: '6px'}}>
            <Grid container spacing={1}>
                <Grid item xs={12} style={{backgroundColor:'#ccc', fontWeight: 'bold', marginBottom: '8px'}}>
                    <span>DentalPlus Online Forms Admin</span>
                </Grid>
                <Grid item xs={12}>
                    <span>Enter your practice email address and we'll send you a magic-link to log in.</span>
                </Grid>
                <Grid item xs={9}>
                    <TextField fullWidth label="Practice Email Address" variant="outlined" size="small"
                        name="user_email" onBlur={(e) => setValue(e)}/>
                </Grid>
                <Grid item xs={3}>
                    <Button style={{width:'100%'}} variant="outline-primary" onClick={saveForm}>Submit</Button> 
                </Grid>
            </Grid>

        </div>
        :
        <div>ok</div>
        }
    </>
    );
}