import React, { useState, useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Grid from '@material-ui/core/Grid';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import TextField from '@material-ui/core/TextField';
import Popup from 'reactjs-popup';
import SignaturePad from 'react-signature-pad-wrapper';
import { parseRequest } from '../../tools/request';

import Button from 'react-bootstrap/Button';

import ToggleButton from 'react-bootstrap/ToggleButton';
import ButtonGroup from 'react-bootstrap/ButtonGroup';

import '../sigCanvas.css';
// example: ZGVudGFscGx1c3wxfDE=

export default function MedicalForm(props) {
    const sigCanvas = useRef({});
    const signatureEndRef = useRef({});
    const { request } = useParams();
    let navigate = useNavigate();

    const [formSaved, setFormSaved] = useState(false);
    const [formSaveError, setFormSaveError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const [serverPath, setServerPath] = useState('');
    const [pageError, setPageError] = useState(false);
    const [signForm, setSignForm] = useState(false);

    const [requestParams, setRequestParams] = useState({});
    const [questions, setQuestions] = useState([]);
    const [doctor, setDoctor] = useState({ name: '', address: '' });
    const [practice, setPractice] = useState({});
    const [mhNeeded, setMhNeeded] = useState(false);
    const [formNeededCheck, setFormNeededCheck] = useState();

    const [formAnswered, setFormAnswered] = useState(false);
    const [nextForm, setNextForm] = useState('');

    const [checked, setChecked] = useState(false);
    const [radioValue, setRadioValue] = useState('1');

    const radios = [
        { name: 'Active', value: '1' },
        { name: 'Radio', value: '2' },
        { name: 'Radio', value: '3' },
    ];

    useEffect(() => {
        async function fetchData() {

            let sp = '';

            if (process.env.NODE_ENV === "development")
                sp = process.env.REACT_APP_SP;
            else
                sp = window.location.origin;

            setServerPath(sp);

            let params = await parseRequest(request, sp);

            /*let request_parse = await parseRequest(request, sp);
            let request_array = request_parse.request_array; 
        
            let params = {};
            params.cust_id = request_array[0];
            params.practice_id = request_array[1];
            params.patient_id = request_array[2];

            if (request_array.length === 4) {
                // old format
                params.mh_check = request_array[3];
            } else {
                // new format with forms
                params.forms = request_array[3];
                params.mh_check = request_array[4];
            }*/

            setRequestParams(params);

            //console.log(params);

            if (params.mh_check === "n")
                setMhNeeded(false);
            else
                setMhNeeded(true);

            if (!params.cust_id || !params.practice_id || !params.patient_id) {
                setPageError(true);
            }

            const requestOptions = {
                method: 'POST',
                mode: "cors",
                body: JSON.stringify({
                    action: 'get_questions', cust_id: params.cust_id,
                    practice_id: params.practice_id, patient_id: params.patient_id
                })
            };

            fetch(sp + '/api/form_questions.php', requestOptions)
                .then(async response => {

                    const data = await response.json();

                    //console.log(data);

                    // check for error response
                    if (!response.ok) {
                        // get error message from body or default to response status
                        const error = (data && data.message) || response.status;
                        return Promise.reject(error);
                    }

                    setQuestions(data.questions);

                    if (!data.questions)
                        setErrorMessage('There are no questions set for this practice');

                })
                .catch(error => {
                    setPageError(true);
                    //console.error('There was an error!', error);
                });
        }

        //console.log(request);
        setPractice(JSON.parse(localStorage.getItem('practice')));
        fetchData();

        let formList = localStorage.getItem('forms');
        if (formList) {
            let formArr = localStorage.getItem('forms').split(",");
            let formIndex = formArr.indexOf('medform');
            setNextForm(formArr[formIndex + 1]);
        }

    }, [request]);

    const saveForm = () => {
        //console.log(questions);

        if (sigCanvas.current.isEmpty())
            return;

        let sigData = sigCanvas.current.toDataURL();

        const requestOptions = {
            method: 'POST',
            mode: "cors",
            body: JSON.stringify({
                action: 'save_form', cust_id: requestParams.cust_id, practice_id: requestParams.practice_id,
                patient_id: requestParams.patient_id, answers: questions, doctor: doctor, signature: sigData
            })
        };

        //console.log(requestOptions);

        fetch(serverPath + '/api/form_answers.php', requestOptions)
            .then(async response => {

                const data = await response.json();

                // check for error response
                if (response.ok === false) {
                    // get error message from body or default to response status
                    const error = (data && data.message) || response.status;
                    return Promise.reject(error);
                }
                else {
                    setFormSaved(true);
                    navigate("/" + nextForm + "/" + request);
                }
            })
            .catch(error => {
                setFormSaveError(true);
                //console.error('There was an error!', error);
            });
    }

    const skipForm = () => {
        //navigate("/"+nextForm+"/"+request);

        const requestOptions = {
            method: 'POST',
            mode: "cors",
            body: JSON.stringify({
                action: 'save_form', cust_id: requestParams.cust_id, practice_id: requestParams.practice_id,
                patient_id: requestParams.patient_id, skip: 'true'
            })
        };

        //console.log(serverPath + '/api/form_answers.php', requestOptions);

        fetch(serverPath + '/api/form_answers.php', requestOptions)
            .then(async response => {

                const data = await response.json();

                //console.log(response)

                // check for error response
                if (response.ok === false) {
                    // get error message from body or default to response status
                    const error = (data && data.message) || response.status;
                    return Promise.reject(error);
                }
                else {
                    setFormSaved(true);
                    navigate("/" + nextForm + "/" + request);
                }
            })
            .catch(error => {
                setFormSaveError(true);
                //console.error('There was an error!', error);
            });
    }

    const handleSwitchChange = (val, id) => {
        //console.log(id, val)
        let _questions = Array.from(questions);

        for (let i = 0; i < _questions.length; i++) {
            if (_questions[i].item_order === id) {
                _questions[i].answer_yesno = val;
                break;
            }
        }

        //console.log(_questions);

        setFormAnswered(true);
        setQuestions(_questions);
    };

    const handleTextboxChange = (event, id) => {
        let _questions = Array.from(questions);

        for (let i = 0; i < _questions.length; i++) {
            if (_questions[i].item_order === id) {
                _questions[i].answer_detail = event.currentTarget.value;
                break;
            }
        }

        setFormAnswered(true);
        setQuestions(_questions);
    }

    const handleDoctorChange = (event) => {
        //console.log(event.currentTarget);
        let _doctor = doctor;

        if (event.currentTarget.name === "doctors_name")
            _doctor.name = event.currentTarget.value;
        else if (event.currentTarget.name === "doctors_address")
            _doctor.address = event.currentTarget.value;

        setDoctor(_doctor);
    }

    const SectionRow = ({ detail }) => {

        return (
            <Grid item xs={12} style={{ backgroundColor: '#ccc', fontWeight: 'bold', marginBottom: '8px' }}>
                <span>{detail.caption}</span>
            </Grid>
        )

    }

    const NoteRow = ({ detail }) => {

        return (
            <Grid item xs={12} style={{ border: '2px dashed', marginBottom: '8px' }}>
                <span dangerouslySetInnerHTML={{ __html: detail.caption }}></span>
            </Grid>
        )

    }

    const QuestionRow = ({ detail }) => {

        //console.log(detail)

        if (detail.options === "textbox") {
            return (
                <>
                    <Grid item xs={12} md={9} lg={6} xl={5}>
                        <span>{detail.caption}</span>
                    </Grid>
                    <Grid item xs={12} sm={12} lg={6} xl={7}>
                        <TextareaAutosize style={{ width: '100%' }}
                            minRows={3} maxRows={15}
                            onBlur={e => handleTextboxChange(e, detail.item_order)}
                            defaultValue={detail.answer_detail}></TextareaAutosize>
                    </Grid>
                </>
            )
        }

        if (detail.options === "yesnodet") {
            //console.log(detail.answer_yesno)
            return (
                <>
                    <Grid item xs={9} sm={10} lg={6} xl={5}>
                        <span>{detail.caption}</span>
                    </Grid>
                    <Grid item xs zeroMinWidth>
                        <ButtonGroup>
                            <ToggleButton
                                key={detail.item_order + '1'}
                                id={`q${detail.item_order}-y`}
                                type="radio"
                                variant='outline-primary'
                                name={detail.item_order}
                                value='yes'
                                checked={detail.answer_yesno === 'yes'}
                                onChange={(e) => handleSwitchChange(e.currentTarget.value, detail.item_order)}
                            >
                                Yes
                            </ToggleButton>
                            <ToggleButton
                                key={detail.item_order + '2'}
                                id={`q${detail.item_order}-n`}
                                type="radio"
                                variant='outline-primary'
                                name={detail.item_order}
                                value='no'
                                checked={detail.answer_yesno === 'no'}
                                onChange={(e) => handleSwitchChange(e.currentTarget.value, detail.item_order)}
                            >
                                No
                            </ToggleButton>
                        </ButtonGroup>
                    </Grid>
                    <Grid item xs={12} md={12} lg={5} xl={6}>
                        {detail.answer_yesno === "yes" &&
                            <TextareaAutosize style={{ width: '100%' }}
                                minRows={3} maxRows={15} placeholder="More detail, if required"
                                onBlur={e => handleTextboxChange(e, detail.item_order)}
                                defaultValue={detail.answer_detail}></TextareaAutosize>
                        }
                    </Grid>
                </>
            )
        }

        if (detail.options === "yesno") {
            return (
                <>
                    <Grid item xs={9} sm={10} lg={6} xl={5}>
                        <span>{detail.caption}</span>
                    </Grid>
                    <Grid item xs zeroMinWidth>
                        <ButtonGroup>
                            <ToggleButton
                                key={detail.item_order + '1'}
                                id={`q${detail.item_order}-y`}
                                type="radio"
                                variant='outline-primary'
                                name={detail.item_order}
                                value='yes'
                                checked={detail.answer_yesno === 'yes'}
                                onChange={(e) => handleSwitchChange(e.currentTarget.value, detail.item_order)}
                            >
                                Yes
                            </ToggleButton>
                            <ToggleButton
                                key={detail.item_order + '2'}
                                id={`q${detail.item_order}-n`}
                                type="radio"
                                variant='outline-primary'
                                name={detail.item_order}
                                value='no'
                                checked={detail.answer_yesno === 'no'}
                                onChange={(e) => handleSwitchChange(e.currentTarget.value, detail.item_order)}
                            >
                                No
                            </ToggleButton>
                        </ButtonGroup>
                    </Grid>
                    <Grid item xs={12} md={12} lg={5} xl={6} />
                </>
            )
        }

        return null;

    }

    const showSignForm = async () => {
        await setSignForm(true);
        await signatureEndRef.current.scrollIntoView({ behavior: "smooth" });
    }

    const clearSig = () => {
        sigCanvas.current.clear();
    }


    return (
        <div style={{ paddingLeft: '6px', paddingRight: '6px' }}>
            <h1 className="formHeader">{practice.practice_name}</h1>
            {pageError ?
                <>
                    <span>Either the request URL is wrong, or we can't connect to the server.<br />Please try again shortly or contact the practice.</span>
                </>
                :
                <>
                    {mhNeeded || formNeededCheck === 'true' ?
                        <>
                            {!formSaved ?
                                <>
                                    <div style={{ paddingBottom: '6px', fontWeight: 'bold' }}>Please complete the following medical history form
                                        and sign in the box at the bottom.</div>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12} style={{ backgroundColor: '#ccc', fontWeight: 'bold', marginBottom: '8px' }}>
                                            <span>Your GP (Doctor)</span>
                                        </Grid>
                                        <Grid item xs={12} sm={12} lg={6} xl={5}>
                                            <TextField fullWidth label="GP Name" variant="outlined" size="small"
                                                name="doctors_name"
                                                onBlur={e => handleDoctorChange(e)}
                                                defaultValue={doctor.name} />
                                        </Grid>
                                        <Grid item xs={12} sm={12} lg={6} xl={7}>
                                            <TextField fullWidth label="GP Surgery Address" variant="outlined" size="small"
                                                name="doctors_address"
                                                multiline maxRows={6} minRows={2}
                                                onBlur={e => handleDoctorChange(e)}
                                                defaultValue={doctor.address} />
                                        </Grid>
                                        {questions.map((q, index) => {

                                            if (q.item_type === "section") {
                                                return <SectionRow detail={q} key={index} />
                                            }
                                            else if (q.item_type === "note") {
                                                return <NoteRow detail={q} key={index} />
                                            }
                                            else if (q.item_type === "question") {
                                                return <QuestionRow detail={q} key={index} />
                                            }

                                            return null;
                                        })}
                                    </Grid>
                                    {formSaveError &&
                                        <div className="errorText">There was a problem saving the form, please try again shortly.</div>
                                    }
                                    {formAnswered === true ?
                                        <div className="signBox">
                                            <span><b>Please sign in the box below.</b> Rotate your device if you need to make the box bigger.</span>
                                            <SignaturePad ref={sigCanvas} canvasProps={{ className: "signatureCanvas" }} redrawOnResize={true} />
                                            <Grid container spacing={1} ref={signatureEndRef} >
                                                <Grid item xs zeroMinWidth>
                                                    <Button style={{ width: '100%', marginRight: '8px', height: '3.5em' }} variant="success" onClick={saveForm}>Save Form</Button>
                                                </Grid>
                                                <Grid item xs={4} md={2}>
                                                    <Button style={{ width: '100%', height: '3.5em' }} variant="outline-secondary" onClick={clearSig}>Re-sign</Button>
                                                </Grid>
                                            </Grid>
                                        </div>
                                        :
                                        <div className="nothingBox">
                                            <Grid container spacing={1}>
                                                <Grid item xs={9} sm={10} lg={6} xl={5}>
                                                    <span>Please confirm that you have nothing to enter on your medical history.<br />You cannot continue without confirming.</span>
                                                </Grid>
                                                <Grid item xs zeroMinWidth>

                                                    <ButtonGroup>
                                                        <ToggleButton
                                                            key={1}
                                                            id='answered-y'
                                                            type="radio"
                                                            variant='outline-primary'
                                                            name="options"
                                                            value='true'
                                                            checked={formAnswered === true}
                                                            onChange={(val) => setFormAnswered(true)}
                                                        >
                                                            Yes
                                                        </ToggleButton>
                                                        <ToggleButton
                                                            key={2}
                                                            id='answered-n'
                                                            type="radio"
                                                            variant='outline-primary'
                                                            name="options"
                                                            value='false'
                                                            checked={formAnswered === false}
                                                            onChange={(val) => setFormAnswered(false)}
                                                        >
                                                            No
                                                        </ToggleButton>
                                                    </ButtonGroup>
                                                </Grid>
                                            </Grid>
                                        </div>

                                    }
                                </>
                                :
                                <>
                                    <div>Thank You for completing your medical history, we look forward to seeing you at your next appointment.</div>
                                </>
                            }
                        </>
                        :
                        <>

                            <div className="nothingBox">
                                <Grid container spacing={1}>
                                    <Grid item xs={9} sm={10} lg={6} xl={5}>
                                        <span>Has anything changed on your medical history since your last visit?</span>
                                    </Grid>
                                    <Grid item xs zeroMinWidth>
                                        <ButtonGroup>
                                            <ToggleButton
                                                key={1}
                                                id='needed-y'
                                                type="radio"
                                                variant='outline-primary'
                                                name="radio"
                                                value='true'
                                                checked={formNeededCheck === 'true'}
                                                onChange={(e) => setFormNeededCheck(e.currentTarget.value)}
                                            >
                                                Yes
                                            </ToggleButton>
                                            <ToggleButton
                                                key={2}
                                                id='needed-n'
                                                type="radio"
                                                variant='outline-primary'
                                                name="radio"
                                                value='false'
                                                checked={formNeededCheck === 'false'}
                                                onChange={(e) => setFormNeededCheck(e.currentTarget.value)}
                                            >
                                                No
                                            </ToggleButton>
                                        </ButtonGroup>
                                    </Grid>
                                    {formNeededCheck === 'false' &&
                                        <Grid item xs={12}>
                                            <Button style={{ width: '100%', marginRight: '8px', height: '3.5em' }} variant="success" onClick={skipForm}>Continue</Button>
                                        </Grid>
                                    }
                                </Grid>
                            </div>
                        </>

                    }
                </>
            }
        </div>
    );
}