export async function parseRequest(request, sp) {

    let request_text;
    let request_array = [];
    let request_object = {};

    if (request.length === 6) {
        // look up the request from the requests database
        let request_lookup = await fetch(sp+'/api/make_request.php?action=get_request&request_key='+request);
        let request_result = await request_lookup.json();

        if (request_result.key && request_result.request_type === 'b') {
            request_array = atob(request_result.request).split('|');
            request_object.cust_id = request_array[0];
            request_object.practice_id = request_array[1];
            request_object.patient_id = request_array[2];
            request_object.forms = request_array[3];
            request_object.mh_check = request_array[4];
            request_object.nhs = request_array[5];
            request_object.age = request_array[6];

            //console.log(request_object);

        } else if (request_result.key && request_result.request_type === 'c') {
            let validforms = {};
            validforms['m'] = 'medform';
            validforms['v'] = 'covid';
            validforms['f'] = 'fp17pr';
            validforms['c'] = 'consent';
            validforms['d'] = 'deposit';
            validforms['p'] = 'contact';

            let requesttext = JSON.parse(request_result.request);

            //console.log(requesttext);

            //console.log(eval(request_result.request));
            request_array = requesttext.link.split("|");
            request_object.cust_id = request_array[0];
            request_object.practice_id = request_array[1];
            request_object.patient_id = request_array[2];

            if (requesttext.forms.length > 0) {
                let forms = requesttext.forms.split("");
                let sf = [];

                for (let i=0; i<forms.length; i++) {
                    sf.push(validforms[forms[i]]);
                }

                request_object.forms = sf.join(',');
            } else {
                request_object.forms = 'all';
            }

            request_object.mh_check = requesttext.mh_check;
            request_object.nhs = requesttext.nhs_patient;
            request_object.age = requesttext.age;

            //console.log(request_object);

        }
    } else {
        request_array = atob(request).split("|");

        request_object.cust_id = request_array[0];
        request_object.practice_id = request_array[1];
        request_object.patient_id = request_array[2];
        request_object.forms = request_array[3];
        request_object.mh_check = request_array[4];
        request_object.nhs = request_array[5];
        request_object.age = request_array[6];

        //console.log(request_object);
    }

    return request_object;
}