import React, { useState, useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import SignaturePad from "react-signature-pad-wrapper";

import Button from "react-bootstrap/Button";

import ToggleButton from "react-bootstrap/ToggleButton";
import ToggleButtonGroup from "react-bootstrap/ToggleButtonGroup";
import { parseRequest } from "../../tools/request";

import "../sigCanvas.css";
import "./fp17pr.css";
// example: ZGVudGFscGx1c3wxfDE=

export default function FP17PR(props) {
  const sigCanvas = useRef({});
  const signatureEndRef = useRef({});
  const { request } = useParams();
  let navigate = useNavigate();

  const [formSaved, setFormSaved] = useState(false);
  const [formSaveError, setFormSaveError] = useState(false);

  const [u18, setu18] = useState(false);

  const [serverPath, setServerPath] = useState("");
  const [pageError, setPageError] = useState(false);

  const [requestParams, setRequestParams] = useState({});
  const [questions, setQuestions] = useState({ "free-entitled": "n" });
  const [practice, setPractice] = useState({});
  const [errors, setErrors] = useState([]);

  const [nextForm, setNextForm] = useState("");

  const blankExemp = {
    exrmcd: null,
    "exemp-mother": null,
    "exemp-college": null,
    "exemp-born": null,
    "exemp-person": null,
    "exemp-persondob": null,
    "exemp-personni": null,
    "hc2-certno": null,
    "tax-certno": null,
    "hc3-certno": null,
    "hc3-amount": null,
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    validateForm();
  }, [questions]);

  useEffect(() => {
    if (u18 === false) clearExemptions(questions["free-entitled"]);
  }, [questions["free-entitled"]]);

  async function fetchData() {
    setPractice(JSON.parse(localStorage.getItem("practice")));

    let sp = "";

    if (process.env.NODE_ENV === "development") sp = process.env.REACT_APP_SP;
    else sp = window.location.origin;

    setServerPath(sp);

    let params = await parseRequest(request, sp);

    //console.log(params);

    /*let request_parse = await parseRequest(request, sp);
            let request_array = request_parse.request_array; 
    
            let params = {};
            params.cust_id = request_array[0];
            params.practice_id = request_array[1];
            params.patient_id = request_array[2];
    
            console.log(request_array);*/

    setRequestParams(params);

    if (params.age < 18) {
      setu18(true);

      //console.log("under 18");

      setQuestions({ "free-entitled": "y", exrmcd: "8" });
    }

    if (!params.cust_id || !params.practice_id || !params.patient_id) {
      setPageError(true);
    }

    let formArr = localStorage.getItem("forms").split(",");
    let formIndex = formArr.indexOf("fp17pr");
    setNextForm(formArr[formIndex + 1]);
  }

  const saveForm = () => {
    //console.log(questions);

    if (sigCanvas.current.isEmpty())
            return;

    let sigData = sigCanvas.current.toDataURL();

    const requestOptions = {
      method: "POST",
      mode: "cors",
      body: JSON.stringify({
        action: "save_fp17pr_form",
        cust_id: requestParams.cust_id,
        practice_id: requestParams.practice_id,
        patient_id: requestParams.patient_id,
        answers: questions,
        signature: sigData,
      }),
    };

    //console.log(requestOptions);

    fetch(serverPath + "/api/form_answers.php", requestOptions)
      .then(async (response) => {
        const data = await response.json();

        // check for error response
        if (response.ok === false) {
          // get error message from body or default to response status
          const error = (data && data.message) || response.status;
          return Promise.reject(error);
        } else {
          //setFormSaved(true);
          //console.log("form saved");
          navigate("/" + nextForm + "/" + request);
        }
      })
      .catch((error) => {
        setFormSaveError(true);
        //console.error('There was an error!', error);
      });
  };

  const handleSwitchChange = (val, id) => {
    //console.log(val, id);
    let _questions = Array.from(questions);

    for (let i = 0; i < _questions.length; i++) {
      if (_questions[i].item_key === id) {
        _questions[i].answer = val;
        break;
      }
    }

    setQuestions(_questions);
  };

  const handleCheckBoxChange = (e) => {
    let val = "n";

    if (e.target.checked === true) val = "y";

    setQuestions({ ...questions, [e.target.id]: val });
  };

  const handleInputChange = (e) => {
    setQuestions({ ...questions, [e.target.id]: e.target.value });
  };

  const clearSig = () => {
    sigCanvas.current.clear();
  };

  const clearExemptions = (yesno) => {
    let _questions = { ...questions };

    for (let i = 0; i < Object.keys(blankExemp).length; i++)
      _questions[Object.keys(blankExemp)[i]] =
        blankExemp[Object.keys(blankExemp)];

    setQuestions(_questions);
  };

  const validateForm = () => {
    let errors = [];

    /*if (questions["evidence"] === "Y") {
                switch(questions.exrmcd) {
                    case "1":
                        if (!questions["exemp-college"])
                            errors.push("exemp-college");
                    break;
                    case "2":
                        if (!questions["exemp-mother"])
                            errors.push("exemp-mother");
                        if (!questions["exemp-born"])
                            errors.push("exemp-born");
                    break;
                    case "3":
                        if (!questions["exemp-mother"])
                            errors.push("exemp-mother");
                        if (!questions["exemp-born"])
                            errors.push("exemp-born");
                    break;
                    case "4":
                        if (!questions["hc2-certno"])
                            errors.push("hc2-certno");
                    break;
                    case "10":
                        if (!questions["tax-certno"])
                            errors.push("tax-certno");
                    break;
                    case "5":
                        if (!questions["hc3-amount"] && !questions["hc3-certno"])
                            errors.push("exemp-hc3");
                    break;
                }
            }*/

    if (questions["free-entitled"] === "y" && !questions["exrmcd"])
      errors.push("no-exemp");

    setErrors(errors);
  };

  return (
    <div style={{ paddingLeft: "6px", paddingRight: "6px" }}>
      <h1 className="formHeader">{practice.practice_name}</h1>
      {pageError ? (
        <>
          <span>
            Either the request URL is wrong, or we can't connect to the server.
            <br />
            Please try again shortly or contact the practice.
          </span>
        </>
      ) : (
        <>
          {!formSaved && (
            <>
              <div className="fp17-header">
                Please read carefully and complete the following form, then sign
                in the box at the bottom.
              </div>
              <p>
                I consent to the dental provider named above, or their
                representative, to examine me under the NHS and to give me any
                necessary care and treatment that I am willing to undergo within
                NHS arrangements. I agree to pay the statutory charges for the
                NHS dental service I receive, unless I have completed a valid
                claim for free or reduced cost NHS dental services below, and
                that I may have to pay the full amount prior to treatment. I
                agree, if necessary, to be examined and/or to have my dental
                records examined by the NHS Business Services Authority (NHSBSA)
                or other authorised bodies. I declare that the information I
                give on this form is correct and complete. I understand that if
                it is not, appropriate action may be taken against me.{" "}
              </p>

              <p className="fp17-section">
                To enable the NHS to prevent and detect fraud and mistakes, pay
                dentists and to secure the effective and efficient delivery of
                NHS and related services, relevant information on your NHS
                treatment may be shared with, and by the NHSBSA to NHS England,
                Department for Work and Pensions, HM Revenue &amp; Customs, NHS
                Digital, NHS Counter Fraud Authority, NHS Service Commissioners
                and bodies performing functions on their behalf. Your personal
                data will be deleted within 10 years of receipt into our
                systems. Further details are available at{" "}
                <a
                  href="http://www.nhsbsa.nhs.uk/yourinformation"
                  target="_blank"
                >
                  www.nhsbsa.nhs.uk/yourinformation
                </a>
              </p>
              <p>What is your ethnic group?</p>
              <p>
                Please choose ONE selection from this list to indicate your
                ethnic group:
              </p>
              <select id="ethorg" onChange={handleInputChange}>
                <option value="99">Patient Declined</option>
                <option value="01">White British</option>
                <option value="02">White Irish</option>
                <option value="03">White Other</option>
                <option value="04">White and Black Caribbean</option>
                <option value="05">White and Black African</option>
                <option value="06">White and Asian</option>
                <option value="07">Other Mixed Background</option>
                <option value="08">Asian or Asian British Indian</option>
                <option value="09">Asian or Asian British Pakistani</option>
                <option value="10">Asian or Asian British Bangladeshi</option>
                <option value="11">Other Asian Bbackground</option>
                <option value="12">Black or Black British Caribbean</option>
                <option value="13">Black or Black British African</option>
                <option value="14">Other Black Background</option>
                <option value="15">Chinese</option>
                <option value="16">Any Other Ethnic Group</option>
              </select>
              <p className="fp17-section">
                Please provide your preferred method of contact below, as an
                alternative to your postal address
              </p>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={2}>
                  <span>Email Address</span>
                </Grid>
                <Grid item xs={12} sm={10} className="input-column">
                  <input
                    type="email"
                    id="email-address"
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <span>Mobile number</span>
                </Grid>
                <Grid item xs={12} sm={10} className="input-column">
                  <input
                    type="tel"
                    id="mobile-no"
                    onChange={handleInputChange}
                  />
                </Grid>
              </Grid>
              <p>
                By providing this information, the NHSBSA may use this method to
                contact you to survey your NHS dentistry experience.
              </p>

              <p className="fp17-highlight">
                <label>
                  <input
                    type="checkbox"
                    id="free-entitled"
                    value="y"
                    onChange={handleCheckBoxChange}
                    checked={questions["free-entitled"] === "y"}
                  />
                  &nbsp;I am entitled to free or reduced cost NHS dental
                  treatment
                </label>
              </p>

              {questions["free-entitled"] === "y" && (
                <>
                  <p className="fp17-section">
                    <b>CLAIM FOR FREE OR REDUCED COST NHS DENTAL SERVICES</b>
                  </p>
                  <p>
                    <b>
                      YOU MUST READ THIS FORM BEFORE YOU SIGN IT. ONLY SIGN IT
                      IF IT IS CORRECT.
                    </b>
                  </p>
                  <p>
                    <b>
                      The patient is responsible for the accuracy of this claim,
                      NOT the dental practice. If you're not certain that you're
                      entitled to receive free or reduced cost NHS dental
                      services you MUST pay the dental practice.
                    </b>{" "}
                    If you subsequently confirm that you were entitled to free
                    or reduced cost dental services, you can claim a refund. If
                    you have applied for a qualifying benefit or exemption
                    certificate but have not received it yet, you must pay and
                    claim a refund when/if you do receive it.
                  </p>
                  <p>
                    <b>
                      Checks on claims are undertaken to confirm you are
                      entitled. Incorrect claims for free or reduced cost NHS
                      dental services will result in a penalty charge of up to
                      £100, in addition to the cost of NHS dental services. You
                      won't have the opportunity to pay for the services first
                      to avoid the penalty charge.
                    </b>
                  </p>
                  <p className="fp17-section">
                    <b>
                      a) I am entitled to free NHS dental services because on
                      the first day of treatment:
                    </b>
                  </p>
                  <Grid container spacing={1}>
                    {u18 === true && (
                      <>
                        <Grid item xs={12}>
                          <label>
                            <input
                              type="radio"
                              name="exrmcd"
                              id="exrmcd"
                              value="8"
                              onChange={handleInputChange}
                              checked={questions["exrmcd"] === "8"}
                            />
                            &nbsp;I am under 18 years of age.
                          </label>
                        </Grid>
                      </>
                    )}

                    {u18 === false && (
                      <>
                        <Grid item xs={12} sm={6}>
                          <label>
                            <input
                              type="radio"
                              name="exrmcd"
                              id="exrmcd"
                              value="1"
                              onChange={handleInputChange}
                            />
                            &nbsp;I am 18 years of age and in full time
                            education
                          </label>
                        </Grid>
                        <Grid item xs={12} sm={6} className="input-column">
                          {questions.exrmcd === "1" && (
                            <div className="fp17-highlight">
                              <label>
                                <input
                                  type="text"
                                  placeholder="Enter Name of college or university"
                                  id="exemp-college"
                                  onChange={handleInputChange}
                                  defaultValue={questions["exemp-college"]}
                                />
                              </label>
                              {errors.indexOf("exemp-college") >= 0 && (
                                <span className="field-error">
                                  * This information is required
                                </span>
                              )}
                            </div>
                          )}
                        </Grid>
                      </>
                    )}

                    <Grid item xs={12} sm={6}>
                      <label>
                        <input
                          type="radio"
                          name="exrmcd"
                          id="exrmcd"
                          value="2"
                          onChange={handleInputChange}
                        />
                        &nbsp;I am pregnant
                      </label>
                    </Grid>
                    <Grid item xs={12} sm={6} className="input-column">
                      {(questions.exrmcd === "2" ||
                        questions.exrmcd === "3") && (
                        <div className="fp17-highlight">
                          <label>
                            <input
                              type="text"
                              placeholder="NHS Maternity Exemption certificate/card no."
                              id="exemp-mother"
                              onChange={handleInputChange}
                              defaultValue={questions["exemp-mother"]}
                            />
                          </label>
                          {errors.indexOf("exemp-mother") >= 0 && (
                            <span className="field-error">
                              * This information is required
                            </span>
                          )}
                        </div>
                      )}
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <label>
                        <input
                          type="radio"
                          name="exrmcd"
                          id="exrmcd"
                          value="3"
                          onChange={handleInputChange}
                        />
                        &nbsp;I had a baby in the last 12 months{" "}
                      </label>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      {(questions.exrmcd === "2" ||
                        questions.exrmcd === "3") && (
                        <div className="fp17-highlight">
                          <label>
                            Date baby due/born:{" "}
                            <input
                              type="date"
                              placeholder="dd/mm/yyyy"
                              id="exemp-born"
                              onChange={handleInputChange}
                              defaultValue={questions["exemp-born"]}
                            />
                          </label>
                          {errors.indexOf("exemp-born") >= 0 && (
                            <span className="field-error">
                              * This information is required
                            </span>
                          )}
                        </div>
                      )}
                    </Grid>

                    <Grid item xs={12}>
                      <label>
                        <input
                          type="radio"
                          name="exrmcd"
                          id="exrmcd"
                          value="12"
                          onChange={handleInputChange}
                        />
                        &nbsp;I am currently in prison or a young offenders
                        institution
                      </label>
                    </Grid>
                  </Grid>
                  {u18 === false && (
                    <>
                      <p className="fp17-section">
                        <b>
                          b) I am entitled to free NHS dental services because
                          during the course of treatment I get, or am included
                          in an award (as a claimant, partner, or dependent
                          person under 20) of:
                        </b>
                      </p>
                      <Grid container spacing={1}>
                        <Grid item xs={12}>
                          <label>
                            <input
                              type="radio"
                              name="exrmcd"
                              id="exrmcd"
                              value="6"
                              onChange={handleInputChange}
                              readOnly={!u18}
                            />
                            &nbsp;Income Support (Incapacity benefit and
                            Disability Living Allowance does NOT count)
                          </label>
                        </Grid>
                        <Grid item xs={12}>
                          <label>
                            <input
                              type="radio"
                              name="exrmcd"
                              id="exrmcd"
                              value="7"
                              onChange={handleInputChange}
                            />
                            &nbsp;Income-based Jobseeker's Allowance
                            (Contribution-based does NOT count)
                          </label>
                        </Grid>
                        <Grid item xs={12}>
                          <label>
                            <input
                              type="radio"
                              name="exrmcd"
                              id="exrmcd"
                              value="18"
                              onChange={handleInputChange}
                            />
                            &nbsp;Income-related Employment &amp; Support
                            Allowance (Contribution-related does NOT count)
                          </label>
                        </Grid>
                        <Grid item xs={12}>
                          <label>
                            <input
                              type="radio"
                              name="exrmcd"
                              id="exrmcd"
                              value="11"
                              onChange={handleInputChange}
                            />
                            &nbsp;Pension Credit Guarantee Credit (Savings
                            Credit on its own does NOT count)
                          </label>
                        </Grid>
                        <Grid item xs={12}>
                          <label>
                            <input
                              type="radio"
                              name="exrmcd"
                              id="exrmcd"
                              value="21"
                              onChange={handleInputChange}
                            />
                            &nbsp;Universal Credit and meets the criteria. Find
                            out more at{" "}
                            <a
                              href="http://www.nhsbsa.nhs.uk/UC"
                              target="_blank"
                            >
                              www.nhsbsa.nhs.uk/UC
                            </a>
                          </label>
                        </Grid>
                      </Grid>
                      {(questions.exrmcd === "6" ||
                        questions.exrmcd === "7" ||
                        questions.exrmcd === "18" ||
                        questions.exrmcd === "11" ||
                        questions.exrmcd === "21") && (
                        <div className="fp17-highlight">
                          <p>
                            Please complete details below if you are included on
                            an award that is not in your name.
                          </p>
                          <Grid container spacing={1}>
                            <Grid item xs={12} className="input-column">
                              <input
                                type="text"
                                placeholder="Name of person receiving benefit"
                                id="exemp-person"
                                onChange={handleInputChange}
                                defaultValue={questions["exemp-person"]}
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <span>
                                Date of Birth:{" "}
                                <input
                                  type="date"
                                  placeholder="dd/mm/yyyy"
                                  id="exemp-persondob"
                                  onChange={handleInputChange}
                                  defaultValue={questions["exemp-persondob"]}
                                />
                              </span>
                            </Grid>
                            <Grid item xs={12} className="input-column">
                              <input
                                type="text"
                                placeholder="Enter National Insurance Number"
                                id="exemp-personni"
                                onChange={handleInputChange}
                                defaultValue={questions["exemp-personni"]}
                              />
                            </Grid>
                          </Grid>
                          {errors.indexOf("exemp-benefit") >= 0 && (
                            <span className="field-error">
                              * This information is required
                            </span>
                          )}
                        </div>
                      )}
                      <p className="fp17-highlight">
                        DURING THE COURSE OF TREATMENT THESE ARE THE ONLY
                        BENEFITS THAT ENTITLE YOU TO FREE NHS DENTAL SERVICES
                      </p>
                      <p className="fp17-section">
                        <b>
                          c) I am entitled to free NHS dental services because I
                          am named on one of the following certificates that is
                          valid during the course of treatment:
                        </b>
                      </p>
                      <Grid container spacing={1}>
                        <Grid item xs={12} sm={6}>
                          <label>
                            <input
                              type="radio"
                              name="exrmcd"
                              id="exrmcd"
                              value="4"
                              onChange={handleInputChange}
                            />
                            &nbsp;HC2 Certificate
                          </label>
                        </Grid>
                        <Grid item xs={12} sm={6} className="input-column">
                          {questions.exrmcd === "4" && (
                            <div className="fp17-highlight">
                              <label>
                                <input
                                  type="text"
                                  placeholder="Enter Certificate Number"
                                  id="hc2-certno"
                                  onChange={handleInputChange}
                                  defaultValue={questions["hc2-certno"]}
                                />
                              </label>
                              {errors.indexOf("hc2-certno") >= 0 && (
                                <span className="field-error">
                                  * This information is required
                                </span>
                              )}
                            </div>
                          )}
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <label>
                            <input
                              type="radio"
                              name="exrmcd"
                              id="exrmcd"
                              value="10"
                              onChange={handleInputChange}
                            />
                            &nbsp;NHS Tax Credit Exemption Certificate/Card (or
                            entitled to one)
                          </label>
                        </Grid>
                        <Grid item xs={12} sm={6} className="input-column">
                          {questions.exrmcd === "10" && (
                            <div className="fp17-highlight">
                              <label>
                                <input
                                  type="text"
                                  placeholder="Enter Certificate/card Number"
                                  id="tax-certno"
                                  onChange={handleInputChange}
                                  defaultValue={questions["tax-certno"]}
                                />
                              </label>
                              {errors.indexOf("tax-certno") >= 0 && (
                                <span className="field-error">
                                  * This information is required
                                </span>
                              )}
                            </div>
                          )}
                        </Grid>
                      </Grid>
                      <span>
                        (You are not automatically entitled because you receive
                        Tax Credits; there are qualifying conditions, please
                        check at{" "}
                        <a href="http://www.nhs.uk/healthcosts" target="_blank">
                          www.nhs.uk/healthcosts
                        </a>
                        . If you qualify you will be sent an exemption
                        certificate/card, but if you don't have one you can use
                        the award notice as proof).
                      </span>
                      <p className="fp17-section">
                        <b>
                          d) I am entitled to reduced cost NHS dental services
                          because :
                        </b>
                      </p>
                      <Grid container spacing={1}>
                        <Grid item xs={12} sm={6}>
                          <label>
                            <input
                              type="radio"
                              name="exrmcd"
                              id="exrmcd"
                              value="5"
                              onChange={handleInputChange}
                            />
                            &nbsp;I am named on a HC3 certificate that is valid
                            during the course of treatment which limits the
                            amount I have to pay to
                          </label>
                        </Grid>
                        <Grid item xs={12} sm={2}>
                          {questions.exrmcd === "5" && (
                            <label className="fp17-highlight">
                              <input
                                type="number"
                                placeholder="Certificate Value (£)"
                                id="hc3-amount"
                                onChange={handleInputChange}
                                defaultValue={questions["hc3-amount"]}
                              />
                            </label>
                          )}
                        </Grid>
                        <Grid item xs={12} sm={4} className="input-column">
                          {questions.exrmcd === "5" && (
                            <>
                              <label className="fp17-highlight">
                                <input
                                  type="text"
                                  placeholder="Enter Certificate Number"
                                  id="hc3-certno"
                                  onChange={handleInputChange}
                                  defaultValue={questions["hc3-certno"]}
                                />
                              </label>
                              {errors.indexOf("exemp-hc3") >= 0 && (
                                <span className="field-error">
                                  * This information is required
                                </span>
                              )}
                            </>
                          )}
                        </Grid>
                      </Grid>
                    </>
                  )}

                  <p className="fp17-section">
                    I confirm that the information I have given above is correct
                    and complete and that I am entitled to free or reduced cost
                    NHS dental services as above.{" "}
                    <b>
                      I understand that I will have to pay for my treatment and
                      a penalty charge of up to £100, if it is not correct and I
                      am not entitled.
                    </b>
                  </p>
                  <p className="fp17-highlight">
                    <label>
                      <input
                        type="checkbox"
                        id="evidence"
                        value="Y"
                        onChange={handleCheckBoxChange}
                      />
                      &nbsp;I will show/have already show evidence of my
                      exemption to the practice
                    </label>
                  </p>
                </>
              )}
            </>
          )}
        </>
      )}
      {errors.indexOf("no-exemp") >= 0 && (
        <span className="field-error">
          You have selected that you are exempt, but have not chosen an
          exemption above. Please choose an exemption or untick the box to
          continue.
        </span>
      )}
      {errors.length === 0 ? (
        <>
          {formSaveError && (
            <div className="errorText">
              There was a problem saving the form, please try again shortly.
            </div>
          )}
          <p className="fp17-section">
            If you are signing on behalf of the patient give details below:
          </p>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={2}>
              <span>Name</span>
            </Grid>
            <Grid item xs={12} sm={10} className="input-column">
              <input type="text" id="sign-name" onChange={handleInputChange} />
            </Grid>
            <Grid item xs={12} sm={2}>
              <span>Relationship to patient</span>
            </Grid>
            <Grid item xs={12} sm={10} className="input-column">
              <input
                type="text"
                id="sign-relationship"
                onChange={handleInputChange}
              />
            </Grid>
          </Grid>
          <div className="signBox">
            <span>
              <b>Please sign in the box below.</b> Rotate your device if you
              need to make the box bigger.
            </span>
            <SignaturePad
              ref={sigCanvas}
              canvasProps={{ className: "signatureCanvas" }}
            />
          </div>
          <Grid container spacing={1} ref={signatureEndRef}>
            <Grid item xs zeroMinWidth>
              <Button
                style={{ width: "100%", marginRight: "8px", height: "3.5em" }}
                variant="success"
                onClick={saveForm}
              >
                Save Form
              </Button>
            </Grid>
            <Grid item xs={4} md={2}>
              <Button
                style={{ width: "100%", height: "3.5em" }}
                variant="outline-secondary"
                onClick={clearSig}
              >
                Re-sign
              </Button>
            </Grid>
          </Grid>
        </>
      ) : (
        <>
          <div className="field-error">
            Please correct all the form errors before proceeding.
          </div>
        </>
      )}
    </div>
  );
}
