import React, { useState, useEffect } from 'react';
import {
    useNavigate,
    useParams
} from "react-router-dom";
import Button from 'react-bootstrap/Button';
import { parseRequest } from '../../tools/request';

import { loadStripe } from '@stripe/stripe-js';
// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
//const stripePromise = loadStripe('pk_test_AUCurKlElal29OM423ca7jTQ');

export default function App() {
    const { request } = useParams();
    const [stripePromise, setStripePromise] = useState({});
    const [clientSession, setClientSession] = useState('');
    const [practice, setPractice] = useState({});
    const [requestParams, setRequestParams] = useState({});
    const [serverPath, setServerPath] = useState('');
    const [pageError, setPageError] = useState(false);
    let navigate = useNavigate();

    //const stripePromise = loadStripe(practice.stripe_key_public);

    useEffect(() => {
        async function fetchData() {

            let sp = '';

            if (process.env.NODE_ENV === "development")
                sp = process.env.REACT_APP_SP;
            else
                sp = window.location.origin;

            let params = await parseRequest(request, sp);

            setRequestParams(params);

            if (!params.cust_id || !params.practice_id || !params.patient_id) {
                setPageError(true);
            }

            const requestOptions = {
                method: 'POST',
                mode: "cors",
                body: JSON.stringify({
                    action: 'get_practice', cust_id: params.cust_id,
                    practice_id: params.practice_id, patient_id: params.patient_id
                })
            };

            fetch(sp + '/api/form_questions.php', requestOptions)
                .then(async response => {

                    const data = await response.json();

                    // check for error response
                    if (!response.ok) {
                        // get error message from body or default to response status
                        const error = (data && data.message) || response.status;
                        return Promise.reject(error);
                    }

                    //console.log(data);

                    setPractice(data.practice);
                    localStorage.setItem('practice', JSON.stringify(data.practice));

                    document.title = data.practice.practice_name;
                })
                .catch(error => {
                    setPageError(true);
                    //console.error('There was an error!', error);
                });
        }

        fetchData();
    }, [request]);

    useEffect(() => {

        async function fetchCheckoutSession() {

            setStripePromise(loadStripe(practice.stripe_key_public));

            const requestOptions = {
                method: 'POST',
                mode: "cors",
                body: JSON.stringify({
                    cust_id: requestParams.cust_id, practice_id: requestParams.practice_id,
                    patient_id: requestParams.patient_id, request: request,
                    sale_amount: requestParams.forms
                })
            };

            //console.log(requestOptions);

            await fetch(serverPath + '/api/stripe/create.php', requestOptions)
                .then(async response => {
                    const data = await response.json();

                    // check for error response
                    if (!response.ok) {
                        // get error message from body or default to response status
                        const error = (data && data.message) || response.status;
                        return Promise.reject(error);
                    }

                    //console.log(data);
                    setClientSession(data);
                    //console.log(data);
                })
                .catch(error => {
                    setPageError(true);
                    console.error('There was an error!', error);
                });
        };

        async function fetchDojoSession() {
            let sp = '';

            if (process.env.NODE_ENV === "development")
                sp = process.env.REACT_APP_SP;
            else
                sp = window.location.origin;

            let params = await parseRequest(request, sp);

            const requestOptions = {
                method: 'POST',
                mode: "cors",
                body: JSON.stringify({
                    cust_id: practice.cust_id, practice_id: params.practice_id,
                    patient_id: params.patient_id, request: request,
                    sale_amount: requestParams.forms
                })
            };

           // console.log(requestOptions);

            await fetch(sp + '/api/dojo/create.php', requestOptions)
                .then(async response => {
                    const data = await response.json();

                    // check for error response
                    if (!response.ok) {
                        // get error message from body or default to response status
                        const error = (data && data.message) || response.status;
                        return Promise.reject(error);
                    }

                    //console.log(data);
                    setClientSession(data);
                    //console.log(data);
                })
                .catch(error => {
                    setPageError(true);
                    console.error('There was an error!', error);
                });
        };

        //console.log(practice);

        if (practice.stripe_key_public === 'dojo')
            fetchDojoSession();
        else if (practice.stripe_key_public)
            fetchCheckoutSession();

    }, [practice, requestParams]);



    const handleClick = async (event) => {
        //console.log(await fetchCheckoutSession());
        //await fetchCheckoutSession();
        //console.log(stripePromise);

        const { sessionId } = clientSession;

        //console.log(sessionId);

        if (practice.stripe_key_public === 'dojo') {
            //navigate(`https://pay.dojo.tech/checkout/${sessionId}`);
            var redirectLink = "https://pay.dojo.tech/checkout/" + sessionId
            window.location.replace(redirectLink)
        } else {
            const stripe = await stripePromise;
            const { error } = await stripe.redirectToCheckout({
                sessionId,
            });
        }
    };

    return (
        <div className="welcomePage" style={{ paddingLeft: '6px', paddingRight: '6px' }}>
            <div className="formHeader"><h1>{practice.practice_name}</h1>
                {practice.practice_contact}</div><br />
            {!pageError ?
                <>
                    <p>We need you make a payment for your dental treatment.<br />Please click below to be taken to our payment processor.</p>
                    <Button style={{ width: '100%', height: '3.5em' }} variant="outline-primary" onClick={handleClick}>Click/touch here to make your payment</Button>
                </>
                :
                <>
                    <p>The payment system is currently unavailable. Please try again later.</p>
                </>
            }
        </div>

    );
}
