import React from 'react';
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";
import './App.css';

import 'bootstrap/dist/css/bootstrap.min.css';

import Welcome from './pages/welcome';
import MedicalForm from './pages/medform';
import CovidForm from './pages/covid';
import ConsentForm from './pages/consent';
import PaymentForm from './pages/payment';  // deposits
import PayForm from './pages/pay';  // payment request
import ThankYou from './pages/thankyou';
import ContactForm from './pages/contact';
import ConfigInit from './pages/configInit';
import FP17PR from './pages/fp17pr';

function Home() {
  return <h2>DentalPlus Patient Forms System</h2>;
}

function App() {
  return (
    <BrowserRouter>
      <div>
        <Routes>
          <Route path={`${process.env.PUBLIC_URL}/medform/:request`} element={<MedicalForm />}/>
          <Route path={`${process.env.PUBLIC_URL}/covid/:request`} element={<CovidForm />}/>
          <Route path={`${process.env.PUBLIC_URL}/consent/:request`} element={<ConsentForm /> }/>
          <Route path={`${process.env.PUBLIC_URL}/fp17pr/:request`} element={<FP17PR /> }/>
          <Route path={`${process.env.PUBLIC_URL}/payment/:request`} element={<PaymentForm />}/>
          <Route path={`${process.env.PUBLIC_URL}/pay/:request`} element={<PayForm />}/>
          <Route path={`${process.env.PUBLIC_URL}/contact/:request`} element={<ContactForm />}/>
          <Route path={`${process.env.PUBLIC_URL}/thankyou/:request/:sessionID`} element={<ThankYou />}/>
          <Route path={`${process.env.PUBLIC_URL}/thankyou/:request`} element={<ThankYou />}/>
          <Route path={`${process.env.PUBLIC_URL}/config`} element={<ConfigInit />}/>
          <Route path={`${process.env.PUBLIC_URL}/:request`} element={<Welcome />}/>
          <Route path="/" element={<Home />}/>
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
