import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  useNavigate,
  useParams
} from "react-router-dom";
import Button from 'react-bootstrap/Button';
import { parseRequest } from '../../tools/request';

import { loadStripe } from '@stripe/stripe-js';
// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
//const stripePromise = loadStripe('pk_test_AUCurKlElal29OM423ca7jTQ');

export default function App() {
  const { request } = useParams();
  const [stripePromise, setStripePromise] = useState({});
  const [clientSession, setClientSession] = useState('');
  const [practice, setPractice] = useState({});
  const [pageError, setPageError] = useState(false);
  let navigate = useNavigate();

  //const stripePromise = loadStripe(practice.stripe_key_public);

  useEffect(() => {
    let practice = JSON.parse(localStorage.getItem('practice'));
    setPractice(practice);

    if (practice.stripe_key_public === 'dojo') {
      async function fetchDojoSession() {
        let sp = '';

        if (process.env.NODE_ENV === "development")
          sp = process.env.REACT_APP_SP;
        else
          sp = window.location.origin;

        let params = await parseRequest(request, sp);

        const requestOptions = {
          method: 'POST',
          mode: "cors",
          body: JSON.stringify({
            cust_id: practice.cust_id, practice_id: params.practice_id,
            patient_id: params.patient_id, request: request
          })
        };

        //console.log(requestOptions);

        await fetch(sp + '/api/dojo/create.php', requestOptions)
          .then(async response => {
            const data = await response.json();

            // check for error response
            if (!response.ok) {
              // get error message from body or default to response status
              const error = (data && data.message) || response.status;
              return Promise.reject(error);
            }

            console.log(data);
            setClientSession(data);
          })
          .catch(error => {
            setPageError(true);
            console.error('There was an error!', error);
          });
      };

      fetchDojoSession();
    } else {

      setStripePromise(loadStripe(practice.stripe_key_public));

      async function fetchCheckoutSession() {
        let sp = '';

        if (process.env.NODE_ENV === "development")
          sp = process.env.REACT_APP_SP;
        else
          sp = window.location.origin;

        let params = await parseRequest(request, sp);

        const requestOptions = {
          method: 'POST',
          mode: "cors",
          body: JSON.stringify({
            cust_id: practice.cust_id, practice_id: params.practice_id,
            patient_id: params.patient_id, request: request
          })
        };

        //console.log(requestOptions);

        await fetch(sp + '/api/stripe/create.php', requestOptions)
          .then(async response => {
            const data = await response.json();

            // check for error response
            if (!response.ok) {
              // get error message from body or default to response status
              const error = (data && data.message) || response.status;
              return Promise.reject(error);
            }

            //console.log(data);
            setClientSession(data);
            //console.log(data);
          })
          .catch(error => {
            //setPageError(true);
            console.error('There was an error!', error);
          });
      };

      fetchCheckoutSession();
    }
  }, [request]);



  const handleClick = async (event) => {
    //console.log(await fetchCheckoutSession());
    //await fetchCheckoutSession();
    //console.log(stripePromise);

    const { sessionId } = clientSession;

    console.log(sessionId);

    if (practice.stripe_key_public === 'dojo') {
      navigate(`https://pay.dojo.tech/checkout/${sessionId}`);
    } else {
      const stripe = await stripePromise;
      const { error } = await stripe.redirectToCheckout({
        sessionId,
      });
    }
  };

  return (
    <Routes>
      <Route path={`${process.env.PUBLIC_URL}/success/:request`} element={<span>Well Done</span>} />
      <Route path={`${process.env.PUBLIC_URL}/cancel`} element={<span>You didn't pay</span>} />
      <Route path={process.env.PUBLIC_URL} element={
        <div className="welcomePage" style={{ paddingLeft: '6px', paddingRight: '6px' }}>
          <div className="formHeader"><h1>{practice.practice_name}</h1>
            {practice.practice_contact}</div><br />
          {!pageError ?
            <>
              <p>We need you make a payment for your upcoming dental treatment.<br />Please click below to be taken to our payment processor.</p>
              <Button style={{ width: '100%', height: '3.5em' }} variant="outline-primary" onClick={handleClick}>Click/touch here to make your payment</Button>
            </>
            :
            <>
              <p>The payment system is currently unavailable. Please try again later.</p>
            </>
          }
        </div>} />
    </Routes>

  );
}
