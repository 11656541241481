import React, { useState, useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Grid from '@material-ui/core/Grid';
import SignaturePad from 'react-signature-pad-wrapper';
import { parseRequest } from '../../tools/request';

import Button from 'react-bootstrap/Button';

import ToggleButton from 'react-bootstrap/ToggleButton';
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';

import '../sigCanvas.css';
// example: ZGVudGFscGx1c3wxfDE=

export default function ConsentForm(props) {
    const sigCanvas = useRef({});
    const signatureEndRef = useRef({});
    const { request } = useParams();
    let navigate = useNavigate();

    const [formSaved, setFormSaved] = useState(false);
    const [formSaveError, setFormSaveError] = useState(false);

    const [serverPath, setServerPath] = useState('');
    const [pageError, setPageError] = useState(false);

    const [requestParams, setRequestParams] = useState({});
    const [questions, setQuestions] = useState([]);
    const [practice, setPractice] = useState({});

    const [nextForm, setNextForm] = useState('');

    useEffect(() => {
        async function fetchData() {
            setPractice(JSON.parse(localStorage.getItem('practice')));
            
            let sp = '';

            if (process.env.NODE_ENV === "development")
                sp = process.env.REACT_APP_SP;
            else
                sp = window.location.origin;
            
            setServerPath(sp);

            let params = await parseRequest(request, sp);    
            setRequestParams(params);

            let formArr = localStorage.getItem('forms').split(",");
            let formIndex = formArr.indexOf('contact');
            setNextForm(formArr[formIndex + 1]);
        }

        fetchData();
    }, []);

    const saveForm = () => {
        //console.log(questions);

        const requestOptions = {
            method: 'POST',
            mode: "cors",
            body: JSON.stringify({
                action: 'save_contact_details', cust_id: requestParams.cust_id, practice_id: requestParams.practice_id,
                patient_id: requestParams.patient_id, phone: questions.phone, email: questions.email
            })
        };

        //console.log(requestOptions);

        fetch(serverPath + '/api/form_answers.php', requestOptions)
            .then(async response => {

                const data = await response.json();

                // check for error response
                if (response.ok === false) {
                    // get error message from body or default to response status
                    const error = (data && data.message) || response.status;
                    return Promise.reject(error);
                }
                else {
                    //setFormSaved(true);
                    //console.log('form saved');
                    navigate("/" + nextForm + "/" + request);
                }
            })
            .catch(error => {
                setFormSaveError(true);
                //console.error('There was an error!', error);
            });
    }

    const handleInputChange = (e) => {
        setQuestions({ ...questions, [e.target.id]: e.target.value });
      };

    const clearSig = () => {
        sigCanvas.current.clear();
    }

    return (
        <div style={{ paddingLeft: '6px', paddingRight: '6px' }}>
            <h1 className="formHeader">{practice.practice_name}</h1>
            {pageError ?
                <>
                    <span>Either the request URL is wrong, or we can't connect to the server.<br />Please try again shortly or contact the practice.</span>
                </>
                :
                <>
                    {!formSaved &&
                        <>
                            <div style={{ paddingBottom: '6px', fontWeight: 'bold' }}>Please enter the contact details we can use to contact you to remind you
                                about upcoming appointments and to let you know it&apos;s time to come and see us again.</div>
                            <Grid container spacing={1}>
                                <Grid item xs={12} sm={3}>
                                    <span>Mobile Phone Number:</span>
                                </Grid>
                                <Grid item xs={12} sm={9}>
                                    <input
                                        style={{minWidth: '300px'}}
                                        type="tel"
                                        pattern="[0-9 ]+"
                                        id="phone"
                                        onChange={handleInputChange}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={3}>
                                    <span>Email Address:</span>
                                </Grid>
                                <Grid item xs={12} sm={9}>
                                    <input
                                        style={{minWidth: '300px'}}
                                        type="email"
                                        id="email"
                                        onChange={handleInputChange}
                                    />
                                </Grid>
                            </Grid>
                            {formSaveError &&
                                <div className="errorText">There was a problem saving the details, please try again shortly.</div>
                            }
                            <div className="signBox">

                                <Grid container spacing={1} ref={signatureEndRef} >
                                    <Grid item xs zeroMinWidth>
                                        <Button style={{ width: '100%', marginRight: '8px', height: '3.5em' }} variant="success" onClick={saveForm}>Save Details</Button>
                                    </Grid>
                                </Grid>
                            </div>
                        </>
                    }
                </>
            }
        </div>
    );
}