import React, { useState, useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import SignaturePad from 'react-signature-pad-wrapper';
import { parseRequest } from '../../tools/request';

import Button from 'react-bootstrap/Button';

import ToggleButton from 'react-bootstrap/ToggleButton';
import ButtonGroup from 'react-bootstrap/ButtonGroup';

import '../sigCanvas.css';
// example: ZGVudGFscGx1c3wxfDE=

export default function CovidForm(props) {
    const sigCanvas = useRef({});
    const signatureEndRef = useRef({});
    const { request } = useParams();
    let navigate = useNavigate();

    const [saveFormNow, setSaveFormNow] = useState(false);
    const [formSaved, setFormSaved] = useState(false);
    const [formSaveError, setFormSaveError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const [serverPath, setServerPath] = useState('');
    const [pageError, setPageError] = useState(false);

    const [requestParams, setRequestParams] = useState({});
    const [questions, setQuestions] = useState([]);
    const [practice, setPractice] = useState({});

    const [formAnswered, setFormAnswered] = useState(false);
    const [nextForm, setNextForm] = useState('');

    useEffect(() => { 
        fetchData();
    }, []);

    async function fetchData() {

        setPractice(JSON.parse(localStorage.getItem('practice')));

        let sp = '';

        if (process.env.NODE_ENV === "development")
            sp = process.env.REACT_APP_SP;
        else
            sp = window.location.origin;
        
        setServerPath(sp);

        let params = await parseRequest(request, sp);
    
        /*let request_parse = await parseRequest(request, sp);
        let request_array = request_parse.request_array; 

        let params = {};
        params.cust_id = request_array[0];
        params.practice_id = request_array[1];
        params.patient_id = request_array[2];*/

        setRequestParams(params);

        if (!params.cust_id || !params.practice_id || !params.patient_id) {
            setPageError(true);
        }
    
        const requestOptions = {
            method: 'POST',
            mode: "cors",
            body: JSON.stringify({ action: 'get_covidform', cust_id: params.cust_id, 
            practice_id: params.practice_id, patient_id: params.patient_id })
        };

        fetch(sp+'/api/form_questions.php', requestOptions)
        .then(async function(response) {

            const data = await response.json();

            //console.log(data);

            if (!response.ok) {
                // get error message from body or default to response status
                const error = (data && data.message) || response.status;
                return Promise.reject(error);
            }

            setQuestions(data.questions);

            if (!data.questions)
                setErrorMessage('There are no questions set for this practice');
        })
        .catch(function(error) {
            console.error('There was an error!', error);
        });

        let formArr = localStorage.getItem('forms').split(",");
        let formIndex = formArr.indexOf('covid');
        setNextForm(formArr[formIndex+1]);
    }


    useEffect(() => {
        // check all the questions have been answered
        let allanswered = true;

        for (let i=0; i<questions.length; i++) {
            if (questions[i].answer.length === 0 && questions[i].item_options.includes("rbg¬")) {
                allanswered = false;
                break;
            }
        }

        setFormAnswered(allanswered);

    }, [questions]);

    useEffect(() => {
        if (saveFormNow === true)
            saveForm();
    }, [saveFormNow])

    function saveForm() {

        //console.log(questions);

        if (sigCanvas.current.isEmpty())
            return;

        let sigData = sigCanvas.current.toDataURL();
        let answerArr = [];

        for (let i=0; i<questions.length; i++) {

            if (questions[i].answer.length > 0) {
                if (questions[i].answer === "yes" || questions[i].answer === "no")
                    answerArr.push(questions[i].item_key+"^"+questions[i].item_key+"_"+questions[i].answer);
                else
                    answerArr.push(questions[i].item_key+"^"+questions[i].answer);
            }
        }

        //console.log(answerArr);

        const requestOptions = {
            method: 'POST',
            mode: "cors",
            body: JSON.stringify({ action: 'save_custom_form', cust_id: requestParams.cust_id, practice_id: requestParams.practice_id, 
                patient_id: requestParams.patient_id, answers: answerArr.join('#'), signature: sigData })
        };

        //console.log(serverPath);
        //console.log(requestOptions);

        fetch(serverPath+'/api/form_answers.php', requestOptions)
            .then(async response => {

                const data = await response.json();

                // check for error response
                if (response.ok === false) {
                    // get error message from body or default to response status
                    const error = (data && data.message) || response.status;
                    return Promise.reject(error);
                }
                else {
                    //setFormSaved(true);
                    navigate("/"+nextForm+"/"+request);
                }
            })
            .catch(error => {
                setFormSaveError(true);
                //console.error('There was an error!', error);
            });

    }

    const handleSwitchChange = (val, id) => {
        let _questions = Array.from(questions);

        for (let i=0; i<_questions.length; i++) {
            if (_questions[i].item_key === id) {
                _questions[i].answer = val;
                break;
            }
        }

        setQuestions(_questions);
    };

    const handleTextboxChange = (event, id) => {
        let _questions = Array.from(questions);

        for (let i=0; i<_questions.length; i++) {
            if (_questions[i].item_key === id) {
                _questions[i].answer = event.currentTarget.value;
                break;
            }
        }

        setFormAnswered('true');
        setQuestions(_questions);
    }

    const QuestionRow = ({detail}) => {

        //console.log(detail);

        let item_options = detail.item_options;
        let io = item_options.split("|");

        //if (io[0] === "x") { // all in one grid cell
            let item_detail = io[1].split("¬");

            if (item_detail[0] === "rbg") {
                return (
                <>
                    <Grid item xs={12} sm={10} lg={6} xl={5}>
                        <span>{detail.item_label}</span>
                    </Grid>
                    <Grid item xs zeroMinWidth>
                        {/*<ToggleButtonGroup  name="options"
                                value={detail.answer} type="radio"
                                onChange={(val) => handleSwitchChange(val, detail.item_key)}>
                            <ToggleButton value="yes" variant="outline-primary">Yes</ToggleButton>
                            <ToggleButton value="no" variant="outline-primary">No</ToggleButton>
                        </ToggleButtonGroup>*/}
                        <ButtonGroup>
                            <ToggleButton
                                key={detail.item_id + '1'}
                                id={`q${detail.item_key}-y`}
                                type="radio"
                                variant='outline-primary'
                                name={detail.item_key}
                                value='yes'
                                checked={detail.answer === 'yes'}
                                onChange={(e) => handleSwitchChange(e.currentTarget.value, detail.item_key)}
                            >
                                Yes
                            </ToggleButton>
                            <ToggleButton
                                key={detail.item_id + '2'}
                                id={`q${detail.item_key}-n`}
                                type="radio"
                                variant='outline-primary'
                                name={detail.item_key}
                                value='no'
                                checked={detail.answer === 'no'}
                                onChange={(e) => handleSwitchChange(e.currentTarget.value, detail.item_key)}
                            >
                                No
                            </ToggleButton>
                        </ButtonGroup>
                    </Grid>
                    <Grid item xs={12} md={12} lg={5} xl={6}/>
                </>
                )
            }
            else if (item_detail[0] === "ti" || item_detail[0] === "ta") {
                return (
                <>
                    <Grid item xs={12} sm={10} lg={6} xl={5}>
                        <span>{detail.item_label}</span>
                    </Grid>
                    <Grid item xs zeroMinWidth>
                        <TextField style={{width:item_detail[1]+'px'}}
                            variant="outlined"
                            onBlur={e => handleTextboxChange(e, detail.item_key)}
                            defaultValue={detail.answer}></TextField>
                    </Grid>
                    <Grid item xs={12} md={12} lg={5} xl={6}/>
                </>
                )
            }
        //}
        
        //return null;

    }

    const clearSig = () => {
        sigCanvas.current.clear();
    }

    return (
    <div style={{paddingLeft: '6px', paddingRight: '6px'}}>
        <h1 className="formHeader">{practice.practice_name}</h1>
        { pageError ? 
        <> 
            <span>Either the request URL is wrong, or we can't connect to the server.<br/>Please try again shortly or contact the practice.</span>
        </>
        :
        <>
            { !formSaved  &&
            <>
                <div style={{paddingBottom: '6px', fontWeight: 'bold'}}>Please complete the following COVID-19 screening form 
                and sign in the box at the bottom.</div>
                <Grid container spacing={1}>
                {questions.map((q, index) => {
                    if (q.item_type === "Q") {
                        return <QuestionRow detail={q} key={index}/>
                    }

                    return null;
                })}
                </Grid>
                { formSaveError &&
                    <div className="errorText">There was a problem saving the form, please try again shortly.</div>
                } 
                { formAnswered ?
                <div className="signBox">
                    <span><b>Please sign in the box below.</b> Rotate your device if you need to make the box bigger.</span>
                    <SignaturePad ref={sigCanvas} canvasProps={{className: "signatureCanvas"}}/>
                    <Grid container spacing={1} ref={signatureEndRef} >
                        <Grid item xs zeroMinWidth>
                            <Button style={{width:'100%', marginRight: '8px', height: '3.5em'}} variant="success" 
                                onClick={saveForm}>Save Form</Button>
                        </Grid>
                        <Grid item xs={4} md={2}> 
                            <Button style={{width:'100%', height: '3.5em'}} variant="outline-secondary" onClick={clearSig}>Re-sign</Button>
                        </Grid>
                    </Grid>
                </div>
                :
                <div className="nothingBox">
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <span>Please complete all questions before continuing.</span>
                        </Grid>
                    </Grid>
                </div>
                }
            </>
            }            
        </>
        }
    </div>
    );
}